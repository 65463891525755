// @flow

import React from 'react';

import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import type { PageProps } from '../types';

import Tag from '../components/tag';
import Layout from '../components/layout';

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
  location,
}: PageProps) => (
  <Layout location={location} title={title}>
    <Helmet title={title} />

    <div>
      <h1># Tags</h1>
      <div
        style={{
          display: 'flex',
          marginBottom: '28px',
        }}
      >
        {group.map(tag => (
          <Tag key={tag.fieldValue} tag={tag} />
        ))}
      </div>
    </div>
  </Layout>
);

export default TagsPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
